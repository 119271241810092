.footerr {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}
  .container-title{
 text-align: left;
    background-color: rgb(255, 255, 255);
    
      margin-top: 10px;

    
    
  }
  .cydev{
    background-color: #ffffff;

  }
  .cambiosydevoluciones{
    background-color: #ffffff;
  }
  .cambiosdev{
 
    background-color: rgb(255, 255, 255);
       
  }
  .container-titlee{
    text-align: left;
       background-color: rgb(255, 255, 255);
       margin-bottom: 10px;
       

  
       
       
     }
  .container-devolucion{
    height: 30vh;
    background-color: rgb(255, 255, 255);
    
  }
  .data-container {
    flex: 1;
    padding: 0 20px; /* Ajusta el padding según lo necesites */
    background-color: rgb(255, 255, 255);
  }
  
 h1{
    margin-bottom: 0;
 }
 .cont{
  margin-top: 100px;
 }
.cydev2{
  background-color: #ffffff;
}
.faq{
  margin-top: 20px;
}
.pregunta{
  margin-top: 20px;
  text-align: left;
  margin-bottom: 20px;
}

h3{
  margin-bottom: 20px;
}
.titulodev{
  margin-top: 20px;
}
.devolucion{
  margin-top: 20px;
}
p{
  margin-top: 10px;
  margin-bottom: 10px;
}
.detalle-producto{
    
    font-size: 40px;
    font-family:Bebas Neue, sans-serif;
    margin-top: 30px;
 color: white;
    margin-bottom: 100px;


    text-align: center;
    justify-content: center;

}
.descripcion2{
    text-align: center;
    justify-content: center;
    background-color: rgba(168, 123, 199, 0.5); 
    height:85vh ;
    margin-top: 100px;


margin-bottom: 20px;
}
.imagen-principal  {
    position: relative;

  }
  .imagen-principal img {
    margin-top: 30px;
    position: relative;
    height: 80vh;
    width: 30vw;

  }
    
  .flecha-izquierda,
  .flecha-derecha {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent; /* Establece el fondo transparente */
    border: none; /* Elimina el borde */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  }
  
  .flecha-izquierda {
    left: 0;
  }
  
  .flecha-derecha {
    right: 0;
  }
  

.text{
    margin-top: 10px;
}
.ItemDetalle{
    width: 30vw;
    height: 40vh;
    margin: 80px;
    border-color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    justify-content: center;;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%; /* Ajusta el tamaño máximo de la imagen al contenedor */

  
}
.detalle-producto {
    display: flex;
    flex-direction: row;
  }
  
  .galeria-imagenes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 50px;
    margin-right: 20px; /* Espacio entre la galería y la imagen principal */
  }
  
  .galeria-imagenes img {
    width: 100px; /* Ajusta el ancho de las miniaturas según tus necesidades */
    margin-bottom: 10px;
    margin-left: 20px; /* Espacio entre las miniaturas */
    height: 15vh;
    width: 10vh;
  }
  
  .contador2{
    margin-top: 20px;
  }
  
  .flecha-izquierda,
  .flecha-derecha {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5); /* Cambia el color a semi-transparente */
    border: none;
    cursor: pointer;
    z-index: 1; /* Asegura que estén por encima de la imagen */
  }
  
  .flecha-izquierda {
    left: 0;
  }
  
  .flecha-derecha {
    right: 0;
  }
  .descripcion {
    flex: 1; /* La descripción ocupa todo el espacio restante */
    margin-top: 200px;

    margin-left: 150px;
 text-align: left;
   
  }
  
  .descripcion h2 {
    font-size: 64px;
    margin-bottom: 10px;
    color: black;
  }
  .ver-mas{
    
  }
  .descripcion p {

    color: black;
    font-size: 38px;
  }
  .Filter{
    height: 40px;
    width: 40px;

  
    
}
.filtros {
  display: flex;
  flex-direction: column;
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
}

.back {
    display: flex; /* Establece un contenedor flexible */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan si no caben en una sola fila */
    margin: 10px;
}
.contenedorpadre {
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan si no caben en una sola fila */
    justify-content: center;
    margin-bottom: 40px;

}

.contador{
    margin-top: 100px;
}
.texto{
    margin-top: 100px;
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
color: rgb(0, 0, 0);
}
.datanombre{
    font-size: 50px;
    color: rgb(0, 0, 0);
}

.tituloinicial{
    margin-top: 100px;
    font-family:Bebas Neue, sans-serif;
    font-style: normal;
}

.orden-busqueda-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  .orden-busqueda-container select,
  .orden-busqueda-container input {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  .filtros-subcategoria {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
  }
  
  .filtros-subcategoria button {
    margin-bottom: 5px;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .imagen-principal img{
height: 40vh;
      width: 50vw;

    }
 .descripcion{
  margin-left: 20px;
  margin-right: 20px;
 }
 .galeria-imagenes img{
  display: none;
 }
  
 .descripcion2{
height: auto;
height: 52vh;
width: 100vw;
 }
   
.descripcion h2{
 font-size: 40px;
 margin-top: 20px;
 }
    
 .descripcion {
  margin-top: 0px;
 }
 .contador2{
  margin-top: 20px;
}
  }
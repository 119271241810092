.center-content {
  text-align: center;
  width: 20vw; 
  margin-left: 60px;
  
  margin-top: 20px;
  padding: 20px;/* Centra horizontalmente el bloque */
}
.logo-container {
  text-align: center;
}

.shippin{
  background-color: rgb(255, 255, 255);
}
.logo {
  width: 20vw; /* Ajusta el tamaño del logo según lo necesario */
  height:30vh;
}
.container-data{
  margin-top: 100px;

}
.container-order {
  display: flex;
  justify-content: space-between;
}
.icons{
width: 5vw;
height: 10vh;
}
.cart-items {
  flex: 1;
  margin-top: 50px;
  margin-left: 100px;


}

.radio-container {
  display: inline-block; /* Para que los elementos estén en línea */
  margin-right: 20px; /* Espacio entre los botones */
}



.radio-label {
  cursor: pointer;
   /* Muestra el cursor como puntero */
}

.checkout-form {
  flex: 1;
  margin: 100px;
 
}
.shippin-order{
  background-color: #fff;
}

.checkout-container {
  width: 100%;
  
; /* Ancho máximo para evitar que el contenido se extienda demasiado en pantallas grandes */
  margin: 0 auto; /* Centrar el contenido horizontalmente */
}

.cart-and-form-container {
  display: flex;
  flex-wrap: wrap;
margin-top: 20px;


  
}

.cart-items {
  flex: 1; /* Ocupa todo el espacio disponible */
/* Espacio entre el carrito y el formulario */
}

.checkout-form {
  
  margin-top: auto;
}

.total-price {
  margin-top: 10px; /* Espacio entre los items y el total */
  font-weight: bold; /* Texto en negrita */
}
.checkout-form form {
  display: flex;
  flex-direction: column;
}
.labels{
  display: flex;
  flex-direction: row;
justify-content: center;
}
.checkout-form label {
  margin-bottom: 10px;
}

.checkout-form button {
  margin-top: 10px;
}
 .h2{
  display: none;
 }
 .container-transferencia {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  
}

.imagen-container {
  flex: 1;
}

.imagen-container img {
  width: 100%;
  height: auto;
}

.data-container {
  flex: 1;
  padding: 0 20px; /* Ajusta el padding según lo necesites */
  background-color: rgb(255, 255, 255);
}
/* Estilos para el contenedor principal del formulario de checkout */


/* Estilos para el contenedor de los productos */
.cart-container {
  flex: 1;
  margin-right: 20px;
}

/* Estilos para el contenedor del formulario de checkout */
.form-container {
  flex: 1;
}

/* Estilos para las tarjetas de productos */
.cart-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
table{
  margin-top: 200px;
  padding: 20px;
  border: none;
  border-spacing: 0;
 
}
th{
  padding: 20px;
}
td{
  padding: 20px;
  background-color: rgba(168, 123, 199, 0.5);
 
}
/* Estilos para los bloques del formulario */
.form-block {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.contenedor{
  margin-top: 100px;

 
 }
/* Estilos para los títulos de sección */
.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Estilos para las etiquetas y campos de entrada del formulario */
.form-block label {
  display: block;
  margin-bottom: 10px;
}

.form-block input[type="text"],
.form-block input[type="radio"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.radio-container input[type="radio"] {
  display: none; /* Oculta los radio buttons */
}

/* Estilos para el botón de finalizar compra */
.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
td{
  padding: 20px;
}
.submit-button:hover {
  background-color: #0056b3;
}
.radio-container input[type="radio"]:checked + span {
  font-weight: bold;
  color: #923192; /* Cambia el peso del texto para resaltar la selección */
}


@media (max-width: 768px) {
  .logo {
    width: 40vw; /* Ajusta el tamaño del logo según lo necesario */
    height:20vh;
  }
  .icons{
    width: 15vw; /* Ajusta el tamaño del logo según lo necesario */
    height:8vh;
  }
  .form-block{
    padding: 10px;
  }
  .checkout-form{
    margin: 0;
  }

.ItemDetalle2{
height: 15vh;
width: 25vw;

}
  table{
      padding: 0;
  }
 h6{
  font-size: 15px;
 }
 td{
  padding: 0;
 }
 .cart-items{
  margin-left: 10px;
  display: flex;
  flex-direction: column;
 }
 .center-content {
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; /* Agrega un padding lateral para el espaciado */
}

.center-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px; /* Ajustar según sea necesario */
}

.center-content input[type="text"] {
  width: 100%;
  max-width: 250px;
  margin-bottom: 10px;
  text-align: center;
}

.center-content button {
  width: 100%;
  max-width: 250px;

}
.cart-and-form-container {
  flex-direction: column; /* Cambia a columna en pantallas pequeñas */
}

.cart-items {
  order: 1; /* Resumen de compra primero en pantallas pequeñas */
}

.checkout-form {
  order: 2; /* Formulario de datos después en pantallas pequeñas */
}
.container-transferencia{
  display: none;
}
}
*{ margin:0; }
.App {
  text-align: center;
}
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
    background-color:#f1d7ff;
    background-image: url('./assets/img/flor2.png');
    background-position: right; /* Para centrar la imagen */
    background-repeat: repeat; /* Para que la imagen no se repita */

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Bebas+Neue&display=swap');
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

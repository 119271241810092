.profile-container{
    margin-top: 100px;
}
.div{
    margin-top: 200px;
}

.order-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #ffffff;
  }
  /* Estilos para el contenedor principal del perfil */
.profile-container {

    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  /* Estilos para el título del perfil */
  .profile-container h2 {
    margin-bottom: 10px;
  }
  
  /* Estilos para los detalles del perfil */
  .profile-container div {
    margin-bottom: 10px;
  }
  
  /* Estilos para el botón de logout */
  .profile-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .profile-container button:hover {
    background-color: #0056b3;
  }
  
  /* Estilos para el contenedor de las órdenes */
  .order-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  
  /* Estilos para la caja de cada orden */
  .order-box {
    background-color: rgb(184, 135, 184);
    border-radius: 10px;
    padding: 20px;
    width: 30vw;
text-align: center;
margin-left: 35%;
margin-right: 15%;
    margin-bottom: 20px;
    flex: 0 1 300px; /* Establece el ancho máximo de cada caja de orden */
  }
  .order-box {
    display: flex;
    margin-bottom: 20px;
  }
  
  .order-details {
text-align: left;
    margin-right: 120px;
  }
  
  .order-image {
    flex: 0 0 100px; /* Establece el ancho de la imagen */
  }
  
  .order-image img {
    max-width: 100%;
    height: auto;
  }
  
  .dropdown-menu{
    
    background-color:rgba(168, 123, 199, 0.5);
    margin-top: 20px;
     position: absolute;
     top: 100%;
     right: 0;
     width: 10vw;
 
  }

  @media (max-width: 768px) {
    .dropdown-menu{
      width: 95vw;
    }
    .order-box {
    
      width: 80vw;
      margin-left: 11%;

 
    }
    }
    

.contenedor{
    background-color: rgba(168, 123, 199, 0.5);
width: 50%;

   

 

}
.check-container {
 
    display: flex;
flex-direction:column;
}

.total-container {

justify-content: right;
margin-bottom: 20px;

   
}

.itemCart{
    border-bottom: 1px solid #eee; 
    padding: 100px;
    margin: 20px;
    
    
}
.itemDetailsContainer {
    flex-grow: 1; /* Expandir el contenedor para llenar el espacio disponible */
}


.h2{
    font-size: 10px;
}
 h6{
    font-size: 20px;
    
 }


.emptycart{
    background-color:rgba(168, 123, 199, 0.5);
   margin-top: 20px;
   width: 20vw;
   position: absolute;
   top: 100%;
   right: 0;
}
.cart-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cart-items {
    flex: 1;
}

.cart-summary {
    flex: 0 0 300px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
}

.subtotal {
    font-size: 18px;
    margin-bottom: 10px;
}

.checkout-buttons {
    margin-top: 20px;
}

.checkout-buttons button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.checkout-buttons button:hover {
    background-color: #45a049;
}

.cart-items .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.cart-items .item img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
}

.cart-items .item-details {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.cart-items .item-details h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 5px;
}

.cart-items .item-details p {
    margin: 0;
    font-size: 16px;
    color: #888;
}

.cart-items .item-price {
    font-size: 18px;
    margin-left: auto;
}

.cart-items .delete-button {
    margin-left: auto;
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

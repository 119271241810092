.contenedor2 {
    width: 20vw;
    border-bottom: 1px solid #ccc;
}

.itemCart2 {
    display: flex;
    align-items: center;
}

.infoSection2 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.productInfo2 {
    display: flex;

}

.ItemDetalle2 {
    height: 35vh;
    width: auto;
    margin-right: 10px;
}

.textoCart {
    display: flex;
    flex-direction: column;
}




.botonelimina-container {
    margin-left: auto;
}
.titleprod{
    font-size: 25px;
    text-align: left;
}
.quantityprod{
    font-size: 15px;
    text-align: left;
}
.price{
   text-align: left;
   font-size: 20px;
   margin-top: 40px;
}
.botonelimina button {
    background: none;
    border: none;
    cursor: pointer;
}

.botonelimina button img {
    width: 20px;
    height: 20px;
}

  .contenedor1{
    background-color:rgba(168, 123, 199, 0.5);
   margin-top: 20px;
   margin-right: 50px;
    position: absolute;
    top: 100%;
    right: 0;
    overflow-y: auto;
    height: 50vh;

 

}@media (max-width: 768px) {
.table{
    padding: 0;
}
.contenedor1{
 
    overflow-y: auto;
   
    margin-right:10px ;
}
.contenedor2{
    width: 100vw;
}
.cont{
    margin-top: 0px;
}
}

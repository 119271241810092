.login-container {
  background-color: #e0b0ff;/* Lila */
    color: #000000; /* Negro */
    padding: 20px;
    border-radius: 5px;
    width: 40vw;
    height: 60vh;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
    height: auto;
  }
  .recover-container{
    background-color: #e0b0ff;/* Lila */
    color: #000000; /* Negro */
    padding: 20px;
    border-radius: 5px;
    width: 40vw;
    height: 40vh;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 20px;
  }
  .login-container h2 {
    margin-bottom: 40px;
    color: #000000; /* Blanco */
  }
  
  .login-container label {
    color: #000000; /* Blanco */
    display: block;
    margin-bottom: 30px;
  }
  
  .login-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;

  }
  .recover-container input{
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
  }
  .recover-container button{
    width: 100%;
    padding: 10px;
    background-color: #9370db; /* Lila claro */
    color: #ffffff; /* Blanco */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-container button {
    width: 100%;
    padding: 10px;
    background-color: #9370db; /* Lila claro */
    color: #ffffff; /* Blanco */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #8a2be2; /* Lila oscuro */
  }
  
  .login-container p {
    color: #000000; 
    margin-top: 30px;/* Blanco */
  }
  
  .login-container a {
    color: #9370db; /* Lila claro */
    text-decoration: none;
  }
  
  .login-container a:hover {
    color: #8a2be2; /* Lila oscuro */
  }
  
  .login-container2 {
    background-color: #e0b0ff;/* Lila */
      color: #000000; /* Negro */
      padding: 20px;
      border-radius: 5px;
      width: 40vw;
      height: 85vh;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 100px;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 20px;
    }
    
    .login-container2 h2 {
      margin-bottom: 40px;
      color: #000000; /* Blanco */
    }
    
    .login-container2 label {
      color: #000000; /* Blanco */
      display: block;
      margin-bottom: 30px;
    }
    
    .login-container2 input {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border: none;
      border-radius: 5px;
    }
    
    .login-container2 button {
      width: 100%;
      padding: 10px;
      background-color: #9370db; /* Lila claro */
      color: #ffffff; /* Blanco */
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .login-container2 button:hover {
      background-color: #8a2be2; /* Lila oscuro */
    }
    
    .login-container2 p {
      color: #000000; 
      margin-top: 30px;/* Blanco */
    }
    
    .login-container2 a {
      color: #9370db; /* Lila claro */
      text-decoration: none;
    }
    
    .login-container2 a:hover {
      color: #8a2be2; /* Lila oscuro */
    }
    .recover-pass{
      margin-top: 40px;
    }
    @media (max-width: 768px) {

      .login-container2 {
      
          width: 40vw;
          height: 110vh;
         
        }
        .login-container{
          height: 100vh;
        }
      }
      @media (max-width: 1868px) {

        .login-container2 {
        
            width: 40vw;
            height: auto;
           
          }
          .login-container {
        
             
             
            }
            
        }
.containercn{
    margin-top: 100px;
}
.product-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-card {
    width: 300px;
    height: 560px;
    margin: 40px;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .product-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .product-info {
    padding-top: 10px;
  }
  
  .product-name {
    font-weight: bold;
  }
  
  .product-buttons {
    margin-top: 10px;
  }
  
  .product-buttons button {
    margin-right: 5px;
  }
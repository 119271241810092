body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
    background-color:#f1d7ff;
    background-image: url('./assets/img/flor2.png');
    background-position: right; /* Para centrar la imagen */
    background-repeat: repeat; /* Para que la imagen no se repita */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



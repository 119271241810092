.contenedor2 {
    width: 25vw;
    border-bottom: 1px solid #ccc;
}

.itemCart2 {
    display: flex;
    align-items: center;
}

.infoSection2 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.productInfo2 {
    display: flex;

}

.ItemDetalle2 {
    height: 15vh;
    width: auto;
    margin-right: 10px;
}

.textoCart {
    display: flex;
    flex-direction: column;
}

.texto h2 {

}

.botonelimina-container {
    margin-left: auto;
}
.titleprod{
    font-size: 25px;
    text-align: left;
}
.quantityprodcheck{
    font-size: 15px;
    text-align: left;
margin-top: 100px;

}
.pricecheck{
   text-align: right;
   font-size: 20px;
   margin-top: 40px;
  
}
.botonelimina button {
    background: none;
    border: none;
    cursor: pointer;
}

.botonelimina button img {
    width: 20px;
    height: 20px;
}

